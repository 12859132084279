import './Header.css';
import { NavLink as Link } from "react-router-dom";

function Header(){
  return(
      <>
      <div className="TopLeft">
        <span style={{textTransform: "uppercase"}}>
          <Link to="/">Exclusive Riding Trails</Link></span>
      </div>
      <div className="BottomLeft">
        <a href="tel:+4917624615139" style={{textTransform: "uppercase"}}>0049 176 24615139</a>
      </div>
      <div className="BottomRight">
        <span style={{textTransform: "uppercase"}}>
          <Link to="/impressum">Impressum</Link></span>
      </div>
    </>
  )
}

export default Header