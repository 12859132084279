import './Impressum.css';

function Impressum(){
  return(
    <div className='ImpressumOuter'>
      <div className='ImpressumInner'>
        <p className='ImpressumText'>{`
          Impressum:

    Alexandra Vogt
    Untere Hauptstr. 17
    87754 Kammlach
    Telefon: 0177-6426599
    E-Mail: alexandra@luniks.net
    Internet: http://www.av-ride-aid.de

    Dieses Impressum wurde erstellt und wird aktualisiert mit der Technologie der janolaw AG.
     

    § 1 Allgemeines
    Ihre personenbezogenen Daten (z.B. Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer) werden von uns nur gemäß den Bestimmungen des deutschen Datenschutzrechts und des Datenschutzrechts der Europäischen Union (EU) verarbeitet. Die nachfolgenden Vorschriften informieren Sie neben den Verarbeitungszwecken, Rechtsgrundlagen, Empfängern, Speicherfristen auch über Ihre Rechte und den Verantwortlichen für Ihre Datenverarbeitung. Diese Datenschutzerklärung bezieht sich nur auf unsere Websites. Falls Sie über Links auf unseren Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen Umgang mit Ihren Daten.
     
    § 2 Ihre Rechte als Betroffener
    Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener im Sinne der DSGVO und es stehen Ihnen folgende Rechte gegenüber uns als Verantwortlichen zu:

    1. Recht auf Auskunft
    Sie können im Rahmen des Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen.

    2. Recht auf Berichtigung
    Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie nach Art. 16 DSGVO eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.

    3. Recht auf Löschung
    Sie können unter den Bedingungen des Art. 17 DSGVO die Löschung Ihrer personenbezogenen Daten verlangen.

    4. Recht auf Einschränkung der Verarbeitung
    Sie haben im Rahmen der Vorgaben des Art. 18 DSGVO das Recht, eine Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen.

    5. Recht auf Datenübertragbarkeit
    Sie haben nach Art. 20 DSGVO das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.

    6. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
    Sie haben nach Art. 7 Abs. 3 DSGVO das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.

    7. Recht auf Beschwerde bei einer Aufsichtsbehörde
    Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, steht Ihnen nach Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde (insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes) zu.



    Bitte beachten Sie auch Ihr Widerspruchsrecht nach Art. 21 DSGVO:

    a) Allgemein: begründeter Widerspruch erforderlich
    Erfolgt die Verarbeitung Sie betreffender personenbezogener Daten
    - zur Wahrung unseres überwiegenden berechtigten Interesses (Rechtsgrundlage nach Art. 6 Abs. 1 f) DSGVO) oder
    - im öffentlichen Interesse (Rechtsgrundlage nach Art. 6 Abs. 1 e) DSGVO),
    haben Sie das Recht, jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Widerspruch einzulegen; dies gilt auch für ein auf die Bestimmungen der DSGVO gestütztes Profiling.

    Im Fall des Widerspruchs verarbeiten wir die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

    b) Sonderfall Direktwerbung: einfacher Widerspruch genügt
    Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit und ohne Angabe von Gründen Widerspruch gegen diese Verarbeitung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.

    Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
     

    Verantwortlicher für die Datenverarbeitung:
    Alexandra Vogt
    Untere Hauptstr. 17
    87754 Kammlach
    Telefon: 0177-6426599
    alexandra@luniks.net

    Diese Datenschutzerklärung wurde erstellt und wird aktualisiert mit der Technologie der janolaw AG.`}
        </p>
      </div>
    </div>
  )
}

export default Impressum