import './App.css';
import { useState } from 'react';

// Add logos here and change the number of logos to cycle through
import logo1 from './logo1.svg';
import logo2 from './logo2.svg';
import logo3 from './logo3.svg';
import logo4 from './logo4.svg';
import logo5 from './logo5.svg';
const logos = [logo1, logo2, logo3, logo4, logo5]
const numberOfLogos = 5

function App() {
  const [currentLogo, setCurrentLogo] = useState(0)

  return (
    <div className="App" 
    onClick={()=>{setCurrentLogo(i=>(i+1)%numberOfLogos)}}>
      <div className="Container">
        <img 
          src={logos[currentLogo]} alt="Wonderriding Logo" 
          draggable="false"
          style={{userSelect: "none"}}
        />
          
      </div>
    </div>
  );
}

export default App;
